<template>
  <div>



    <div class="version">
      <div class="message">
        <img @click.stop="messageChange" src="../../assets/Project/message.png" alt />

        <div class="topFlag" v-if="$store.state.unread"></div>
      </div>
      <div class="header_logo">
        <img src="../../assets/Project/logoIcon.png" alt />
      </div>
    </div>
    <div id="container"></div>
    <div class="manage">

      <div class="userPosition">
        <!-- @click="userChange()" -->
        <div class="user">
          <div class="logo">
            <el-popover placement="bottom" width="320" trigger="hover" >

              <div class="listContent">
                <div class="itemTop">
                  <router-link :to="{path:'/OfficialWebsite/HomePage/HomePageContent'}" style="text-decoration: none; color: #000;" >
                  <div class="left">
                    <div class="imgBox">
                      <img class="itemTopImg" src="../../assets/OfficialWebsite/officialWebsite.png" alt="">

                    </div>
                    <span>官方网站</span>
                  </div>
                </router-link>
                <router-link :to="{path:'/OfficialWebsite/HomePage/LearningVideoList'}" style="text-decoration: none;color: #000;" >
                  <div class="right">
                    <div class="imgBox">

                      <img class="itemTopImg" src="../../assets/OfficialWebsite/LearningCommunity.png" alt="">
                    </div>
                    <span>学习社区</span>
                  </div>
                </router-link>

                </div>
                <div class="itemList">
                  
                <router-link :to="{path:'/PersonalData'}" style="text-decoration: none;color: #000;" >
                  <div class="listItem">
                    <div class="listItemIcon">

                      <img class="listItemImg" src="../../assets/OfficialWebsite/editData.png" alt="">

                    </div>
                    <span class="listItemText">编辑个人资料</span>
                    <i class="icon el-icon-arrow-right">

                    </i>
                  </div>
                </router-link>
                <router-link :to="{path:'/index/user/User'}" style="text-decoration: none;color: #000;" >
             
                  <div class="listItem">
                    <div class="listItemIcon">

                      <img class="listItemImg" src="../../assets/OfficialWebsite/teamList.png" alt="">

                    </div>
                    <span class="listItemText">加入团队列表</span>
                    <i class="icon el-icon-arrow-right">

                    </i>
                  </div>
                 
                  </router-link>
                <router-link :to="{path:'/OfficialWebsite/HomePage/introduce'}" style="text-decoration: none;color: #000;" >

                  <div class="listItem">
                    <div class="listItemIcon">

                      <img class="listItemImg" src="../../assets/OfficialWebsite/versionIntroduction.png" alt="">

                    </div>
                    <span class="listItemText">版本介绍</span>
                    <i class="icon el-icon-arrow-right">

                    </i>
                  </div>
                </router-link>
                </div>
                <div class="bottom "  @click="exitChange">
                  退出登录
                </div>

              </div>

              <img slot="reference" class="logo" :src="headPortrait" alt />

            </el-popover>
          </div>
          <div class="number">{{ userName }}
          </div>
        </div>
      </div>





      <div class="pmp">
        <!-- <el-menu
          default-active="2"  
          class="el-menu-vertical-demo"
        >
          <el-menu-item index="2">
            <div class="project" @click="pmpChange">
              <img src="../../assets/Project/checkPmp.png" v-show="pmpState" alt />
              <img src="../../assets/Project/pmp.png" v-show="!pmpState" alt="">
              <span slot="title">个人团队</span>
            </div>
          </el-menu-item>
          <el-menu-item index="1">
            <div class="project" @click="exitChange">
              <img src="../../assets/Project/exit.png" alt />
              <span slot="title">退出</span>
            </div>
          </el-menu-item>
        </el-menu>-->
        <div class="personage">个人项目</div>
        <el-menu default-active="1" class="el-menu-vertical-demo" @select="handleSelect">
          <!-- <el-submenu index="1-1"> -->
          <!-- <template slot="title">

              <span
                :style="
                  handleSelectKey == '1-2' || handleSelectKey == '1-1'
                    ? 'color:rgba(5, 96, 253, 0.7)'
                    : ''
                "
              >个人项目</span>

  
            </template>   -->

          <el-submenu index="1">
            <template slot="title">
              <i class="titleIcon">
                <img v-if="handleSelectKey == '1-2' || handleSelectKey == '1-1'"
                  src="../../assets/group/Individualselected.png" alt />

                <img v-else src="../../assets/group/personage.png" alt />
              </i>
              <el-tooltip class="item" effect="dark" :content="defaultGroup.groupName" placement="top">
                <span class="titleText" :style="
                  handleSelectKey == '1-2' || handleSelectKey == '1-1'
                    ? 'color:rgba(5, 96, 253, 0.7)'
                    : ''
                ">{{ defaultGroup.groupName }}  
</span>
              </el-tooltip>
              <!-- <i class="el-icon-location"></i> -->
            </template>

            <el-menu-item index="1-2" @click="defaultView()">
              <i class="titleIcon">
                <img v-if="handleSelectKey == '1-2'" src="../../assets/group/checkSelect.png" alt />
                <img v-else src="../../assets/group/check.png" alt />
              </i>
              查看
            </el-menu-item>
            <el-menu-item :index="defaultGroup.groupId + '-1'" @click="teamSet(defaultGroup)">
              <i class="titleIcon">
                <img v-if="handleSelectKey == defaultGroup.groupId + '-1'" src="../../assets/group/teamSetUpSelect.png"
                  alt />

                <img v-else src="../../assets/group/teamSetUp.png" alt />
              </i>
              团队设置
            </el-menu-item>

            <el-menu-item index="1-1" @click="createProject(defaultGroup)">
              <i class="titleIcon">
                <img v-if="handleSelectKey == '1-1'" src="../../assets/group/createProjectSelect.png" alt />
                <img v-else src="../../assets/group/createProject.png" alt />
              </i>
              创建项目
            </el-menu-item>

            <!-- <el-menu-item index="1-2">
              <i class="contentIcon"></i>
              1121
            </el-menu-item>-->
          </el-submenu>

          <!-- </el-submenu> -->



          <div class="lineBetween"></div>
          <div class="personage">团队项目</div>

          <div v-for="item in userGroupList" :key="item.groupId">
            <el-submenu :index="item.groupId" :style="
              handleSelectKey == item.groupId + '-3' ||
                handleSelectKey == item.groupId + '-1' ||
                handleSelectKey == item.groupId + '-2'
                ? 'color:rgba(5, 96, 253, 0.7)'
                : ''
            ">
              <template slot="title">
                <i class="titleIcon">
                  <img v-if="handleSelectKey == item.groupId + '-3' ||
                  handleSelectKey == item.groupId + '-1' ||
                  handleSelectKey == item.groupId + '-2'" src="../../assets/group/TeamSelection.png" alt />

                  <img v-else :src="require('../../assets/group/' + item.imgName)" alt />


                </i>
                <el-tooltip class="item" effect="dark" :content="item.groupName" placement="top">

                  <span :style="
                    handleSelectKey == item.groupId + '-3' ||
                      handleSelectKey == item.groupId + '-1' ||
                      handleSelectKey == item.groupId + '-2'
                      ? 'color:rgba(5, 96, 253, 0.7)'
                      : ''
                  ">{{ item.groupName }}


                    <!-- {{'../../assets/group/'+item.imgName}} -->
                  </span>

                </el-tooltip>
              </template>

              <el-menu-item :index="item.groupId + '-3'" @click="viewProjec(item)">
                <i class="titleIcon">
                  <img v-if="handleSelectKey == item.groupId + '-3'" src="../../assets/group/checkSelect.png" alt />
                  <img v-else src="../../assets/group/check.png" alt />
                </i>
                查看
              </el-menu-item>


              <el-menu-item :index="item.groupId + '-1'" @click="teamSet(item)">
                <i class="titleIcon">
                  <img v-if="handleSelectKey == item.groupId + '-1'" src="../../assets/group/teamSetUpSelect.png" alt />

                  <img v-else src="../../assets/group/teamSetUp.png" alt />
                </i>
                团队设置
              </el-menu-item>
              <el-menu-item :index="item.groupId + '-2'" @click="createProject(item)">
                <i class="titleIcon">
                  <img v-if="handleSelectKey == item.groupId + '-2'" src="../../assets/group/createProjectSelect.png"
                    alt />
                  <img v-else src="../../assets/group/createProject.png" alt />
                </i>
                创建项目
              </el-menu-item>
            </el-submenu>
          </div>
          <div class="lineBetween"></div>
          <el-menu-item index="3" @click="exitChange">
            <i class="titleIcon">
              <img src="../../assets/Project/exit.png" alt />
            </i>
            退出
          </el-menu-item>
        </el-menu>
      </div>
      <div class="cloudPosition">
        <div class="cloud">
          <div class="progress_box">
            <el-progress :text-inside="true" :stroke-width="30" :percentage="percentage"></el-progress>
          </div>
          <div class="memory">{{ usedspace | formatbytes }}/{{ storage | formatbytes }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <ProjectCreate ref="ProjectCreate" />
    </div>
    <!--  -->

    <div class="topMessage">
      <Message v-if="message" />
    </div>

    <el-dialog top="10vh" width="50%" @close="setTeamSetVisible()" :visible.sync="teamSetVisible">
      <div class="teamSetTop">
        <div class="teamSetTeamName">
          <!-- <el-input v-model="teamSetTeamName">
 
          </el-input>-->

          <div class="teamName">{{ installGroupData.groupName }}</div>

          <i slot="suffix" class="el-icon-edit icon" @click="changeTeamName()" v-if="permissions != 2"></i>
        </div>

        <div class="teamOperation">
          <div class="teamMembers fl">团队成员列表【{{ totals }}】</div>
          <div class="operation fl" @click.stop="HandeTeamShow()"
            v-if="permissions == 3 && teamSetGroupType == 'joinOrCreate'">移交团队</div>
          <div class="operation fl" @click="myDeleteTeam()"
            v-if="permissions == 3 && teamSetGroupType == 'joinOrCreate'">
            删除团队
          </div>
          <div class="operation fl" @click="myQuitTeam()" v-if="teamSetGroupType == 'joinOrCreate'">退出团队</div>
        </div>

        <div class="transitionTeam" v-show="transitionTeam">
          <div class="transitionTeamList">
            <el-table :data="intendantList" height="13.02083vw" :header-cell-style="rowClass" :cell-style="cellStyle">
              <el-table-column property="userName" align="center" label="成员"></el-table-column>

              <el-table-column property="level" align="center" label="身份权限"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <span class="turn" @click="turnOver(scope.row.userId)">移交团队</span>
                </template>
              </el-table-column>

              <div slot="empty" class="emptyBg">

                <p>暂无管理员</p>
              </div>


            </el-table>
          </div>
        </div>

        <div class="teamSetTopRight fr">
          <div class="addTeam fr" v-if="installGroupData.level != 1" @click="setAddUser()">添加成员</div>
          <div class="sort fr" :class="{ sort: !ScreenShow, sortShow: ScreenShow, fr: true }">
            <i class="el-icon-sort filtrate" @click="sortord()"></i>

            <div class="filtrateContainer" v-show="ScreenShow">
              <p class="filtrateItem" @click="confirmed(type1)" :style="rank == 1 ? `color: rgba(5, 96, 253, 1);` : ''">
                按身份权限排序</p>
              <p class="filtrateItem" @click="confirmed(type2)" :style="rank == 2 ? `color: rgba(5, 96, 253, 1);` : ''">
                按最后操作排序</p>
            </div>
          </div>
          <div class="search fr">
            <el-input v-model="SearchPhone" @change="selectPhone">
              <template slot="prefix">
                <!-- <select name="" id="" class="indistinctOrExact">
                      <option value="1">模糊</option>
                      <option value="2">精准</option>

                </select>-->

                <div class="select">
                  <el-select v-model="folderSearchType" placeholder="请选择" class="indistinctOrExact">
                    <el-option label="手机号" value="1"></el-option>
                    <el-option label="昵称" value="2"></el-option>
                  </el-select>
                </div>
              </template>

              <i slot="suffix" class="el-icon-search icon" @click="selectPhone"></i>
            </el-input>
          </div>
        </div>
      </div>
      <div class="dataTable">
        <el-table :data="gridData" height="26.04167vw" :header-cell-style="rowClass" :cell-style="cellStyle">
          <el-table-column property="userName" align="center" label="成员" width="150"></el-table-column>
          <el-table-column property="updateTime" prop="updateTime" align="center" label="最后操作时间" width="200">
            <template slot-scope="scope">{{ scope.row.updateTime == "" ? "..." : scope.row.updateTime }}</template>
          </el-table-column>
          <el-table-column property="inviter" prop="inviter" align="center" label="邀请人">
            <template slot-scope="scope">{{ scope.row.inviter == "" ? "..." : scope.row.inviter }}</template>
          </el-table-column>
          <el-table-column property="level" align="center" label="身份权限" prop="level">
            <template slot="header">
              <span style="margin-right: 0.65104vw">身份权限</span>

              <i class="el-icon-warning-outline" @click="specificationShow"></i>
            </template>

            <template slot-scope="scope">
              <span v-if="scope.row.level == '超级管理员'"
                :style="scope.row.level == '超级管理员' ? 'color:#0560FD ;marginRight:0.52083vw ;' : ''">{{ scope.row.level
                }}</span>

              <span v-if="scope.row.level == '成员'"
                :style="scope.row.level == '成员' ? 'color:#2FCC88;  marginRight:0.52083vw ;' : ''">{{ scope.row.level
                }}</span>
              <span v-if="scope.row.level == '管理员'" :style="
                scope.row.level == '管理员'
                  ? 'color:#FF7502 ;  marginRight:0.52083vw ;'
                  : ''
              ">{{ scope.row.level }}</span>

              <!-- <span v-if="scope.row.level == '成员'"
                :style="scope.row.level == '成员' ? 'color:#2FCC88;  marginRight:0.52083vw ;' : ''">{{ scope.row.level
                }}</span> -->
              <!-- <span
                v-if="scope.row.level == '管理员'"
                :style="
                  scope.row.level == '管理员'
                    ? 'color:#FF7502 ;  marginRight:0.52083vw ;'
                    : ''
                "

                >{{ scope.row.level }}</span
              >-->
              <!-- {{permissions}} -->

              <!-- <i v-if="permissions == 3">
                <i v-if="scope.row.level != '超级管理员'" style="marginleft: undefined" class="el-icon-setting"
                  @click="membersSet(scope.row)"></i>
              </i>

              <i v-if="permissions == 2">
                <i v-if="scope.row.level == '编辑者' || scope.row.level == '查看者'" style="marginleft: undefined"
                  class="el-icon-setting" @click="membersSet(scope.row)"></i>
              </i> -->
            </template>

          </el-table-column>


          <el-table-column property="inviter" prop="" align="center" label="操作">

            <template slot-scope="scope">




              <span class="settingUp" @click="membersSet(scope.row)"
                v-if="installGroupData.level == 3 || (installGroupData.level == 1 && scope.row.userId == phone) || (installGroupData.level == 0 && scope.row.userId == phone) || (installGroupData.level == 2 && scope.row.level != '超级管理员')">设置</span>

              <span class="partingLine" v-if="scope.row.level != '超级管理员' && installGroupData.level == 3">|</span>
              <span class="authority" @click="permissionSettingClick(scope.row.userId)"
                v-if="scope.row.level != '超级管理员' && installGroupData.level == 3">权限管理</span>


            </template>
          </el-table-column>


        </el-table>
      </div>
      <div class="paging">
        <el-pagination layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-size="pageSize"></el-pagination>
      </div>


      <!-- 设置成员权限 -->
      <el-dialog :visible="permissionSettingShow" width="45%" :modal="false" center append-to-body
        :before-close="permissionSettingClose" top="15vh">
        <div class="accessContent">
          <div class="contentInterval">


            <div class="accessContent_item" v-for=" item in authorityList " :key="item.authority">

              <div class="accessContent_item_left">

                <div class="accessContent_item_left_title">
                  权限主题：{{ item.authorityName }}
                </div>
              </div>
              <div>
                <div class="accessContent_item_right" v-for=" j in item.seedAuthority" :key="j.title">

                  <div class="accessContent_right_item">
                    <div class="accessContent_type">
                      <el-radio v-model="j.extendOfCompetence" @change="changeType(j)" :label="true">
                        <!--  -->
                        <span v-if="j.authority != 'RECYCLED'">

                          授予全部{{ j.title }}权限
                        </span>

                        <span v-else>

                          授予{{ j.title }}权限
                        </span>
                      </el-radio>
                    </div>
                    <div class="accessContent_childRights">

                      <div class="accessContent_childRights_title">
                        <el-radio v-model="j.extendOfCompetence" @change="changeType(j)" :label="false">
                          <span v-if="j.authority != 'RECYCLED'">
                            授予{{ j.title }}权限

                          </span>

                          <span v-else>
                            取消{{ j.title }}权限

                          </span>



                        </el-radio>
                      </div>

                      <div class="accessContent_childRights_content">

                        <el-checkbox-group v-model="j.haveRight">
                          <div class="SelectPermissions" v-for="i in j.seedAuthority" :key="i.authority">
                            <el-checkbox :label="i.authority" :disabled="j.extendOfCompetence">{{ i.authorityName }}
                            </el-checkbox>
                          </div>

                        </el-checkbox-group>
                      </div>


                    </div>
                  </div>

                </div>
              </div>
              <div class="crossLine">
              </div>
            </div>
          </div>
        </div>
        <div class="userBtn">
          <!--  -->
          <div class="btn">
            <div class="ackButton" @click="setPermission()">确认</div>
            <div class="deleteButton" @click="permissionSettingShow = false">取消</div>
          </div>
        </div>
      </el-dialog>


      <!-- 管理成员 -->
      <el-dialog :visible="innerVisible" width="45%" :modal="false" center append-to-body :before-close="handleClose"
        top="15vh">
        <div class="membersSetTitle">
          <div class="titleText">管理成员</div>
        </div>

        <div class="membersSetTop">
          <div class="userData">
            <div class="userImg">
              <img :src="selectUserData.image" alt />
            </div>
            <div class="userPhone">
              <div class="phone">{{ selectUserData.name }}</div>
              <div class="time">{{ selectUserData.createTime }} 进入团队</div>
            </div>
          </div>

          <div class="setStatus">
            <div class="selectBox">
              <p>成员当前权限</p>
              <div class="ipt_box">
                <div v-if="jurisdiction != 3">
                  <!-- -->


                  <el-select class="select" v-model="jurisdiction" placeholder="请选择"
                    :disabled="installGroupData.level != 3">
                    <el-option v-for="item in jurisdictionList" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>

                    <!-- <el-option>管理员</el-option> -->
                  </el-select>
                </div>
                <div v-else>超级管理员</div>

              </div>
            </div>
          </div>
        </div>
        <div class="title">成员行为日志</div>
        <div class="userLog">
          <div v-for="item in userLogList" :key="item.id">
            <p>· {{ item }}</p>
          </div>
        </div>
        <div class="userBtn">
          <div class="btn"
            v-if="(jurisdiction !== 3 && installGroupData.level == 3) || (jurisdiction !== 3 && installGroupData.level == 2)">
            <div class="ackButton" @click="innerOk()">确认</div>
            <div class="deleteButton" @click="innerDelete()">删除成员</div>
          </div>
        </div>
      </el-dialog>

      <!--权限声明 -->
      <!-- : -->

      <el-dialog :visible="permissionStatement" width="45%" :modal="false" center append-to-body
        :before-close="permissionClose" top="23vh">
        <div class="permissionType">
          <div class="permissionTypeLeft">
            <div class="userIcon">
              <img src="../../assets/group/SuperAdmin.png" alt />
              <div class="membership" style="color: #0560fd">超级管理员</div>
            </div>
          </div>

          <div class="permissionTypeRight">
            <div class="permissionName superAdmin">创建团队</div>
            <div class="permissionName superAdmin">删除团队</div>
            <div class="permissionName superAdmin">移交团队</div>
            <div class="permissionName superAdmin">任命团队权限</div>
            <div class="permissionName superAdmin">邀请成员</div>
            <div class="permissionName superAdmin">删除团队</div>
            <div class="permissionName superAdmin">授予成员行为日志</div>
            <div class="permissionName superAdmin">授予成员团队项目</div>

          </div>
        </div>
        <div class="permissionType">
          <div class="permissionTypeLeft">
            <div class="userIcon">
              <img src="../../assets/group/admin.png" alt />
              <div class="membership" style="color: #ff7502">管理员</div>
            </div>
          </div>
          <div class="permissionTypeRight">
            <div class="permissionName admin">接受团队</div>
            <div class="permissionName admin">删除成员</div>
            <div class="permissionName admin">邀请成员</div>
            <div class="permissionName admin">查看成员行为日志</div>
            <div class="permissionName admin">退出团队</div>

          </div>
        </div>

        <div class="permissionType">
          <div class="permissionTypeLeft">
            <div class="userIcon">
              <img src="../../assets/group/bookmaker.png" alt />
              <div class="membership" style="color: #2fcc88">编辑者</div>
            </div>
          </div>
          <div class="permissionTypeRight">
            <div class="permissionName redactor">查看自己行为日志</div>
            <div class="permissionName redactor">退出团队</div>

          </div>
        </div>
      </el-dialog>


      <!-- 正在团队中 -->
      <el-dialog :visible="remind" :modal="true" center width="25%" append-to-body :before-close="remindClose"
        top="23vh">
        <div class="remind">
          <div class="remindIcon">
            <img src="../../assets/group/hint.png" alt />
          </div>
          <div class="remindText">
            您在【{{
                installGroupData.groupName
            }}】中，身份权限为【超级管理员】无法直接退出团队，请先移交身份给其他人！
          </div>
        </div>

        <div class="enter">
          <div class="set verify" @click="remindClose">确认</div>
          <div class="set cancel" @click="remindClose">取消</div>
        </div>
      </el-dialog>

      <!-- 修改团队名称 -->
      <el-dialog :visible="updataName" :modal="true" center width="25%" append-to-body :before-close="updataNameClose"
        top="23vh">
        <div class="name">
          <p>修改团队名称：</p>
          <div class="ipt_box">
            <input class="input" type="text" v-model="newName" placeholder="请输入名称" />
          </div>
        </div>
        <div class="enter">
          <div class="set verify" @click="updataNameOk()">确定</div>
          <div class="set cancel" @click="updataNameClose()">取消</div>
        </div>
      </el-dialog>

      <!-- 添加成员 -->
      <el-dialog :visible="addUserShow" :modal="true" center width="25%" append-to-body :before-close="addUserClose"
        top="23vh">
        <div class="name">
          <p>成员手机号：</p>
          <div class="ipt_box">
            <input class="input" type="text" v-model="addUser" placeholder="请输入" />
          </div>
        </div>
        <div class="enter">
          <div class="set verify" @click="addUserOK()">确认</div>
          <div class="set cancel" @click="addUserClose()">取消</div>
        </div>
      </el-dialog>
    </el-dialog>

    <!-- 历史消息 -->
    <el-dialog :visible="messageHistory" width="25%" :modal="true" title="历史消息" center append-to-body
      :before-close="messageHistoryClose" top="23vh">
      <div class="allMessages" v-if="messageHistory">
        <Message :myPaging="true" :allRead="false" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ProjectCreate from "./ProjectCreate.vue";
import { ISetting } from "../../threejs/interface/ISetting";
import Message from "./Message";
export default {
  name: "About",
  data() {
    return {
      select: "",
      //存储空间
      storage: 0,
      //使用空间
      usedspace: 0,
      //计算百分比
      percentage: 0,
      //头像
      headPortrait: "",

      phone: "",
      pmpState: true,
      message: false,
      //团队id
      teamId: "",

      teamLevel: "",
      //排序方式
      rank: 1,
      userName: "",
      //团队用户列表
      gridData: [],
      // 第几页
      currentPage: 1,
      //每页几条
      pageSize: 10,
      totals: 0,
      //页码
      page: 1,
      //搜索用户
      SearchPhone: "",

      //团队新名称
      teamSetTeamName: "",

      //控制移交团队显示隐藏
      transitionTeam: false,
      //团队设置
      teamSetVisible: false,
      //第二层lialog
      innerVisible: false,

      //权限声明
      permissionStatement: false,

      //历史消息
      messageHistory: false,

      //修改团队名称

      updataName: false,
      //修改的名字
      Name: "",

      // 提醒正在团队中
      remind: false,
      //用户创建团队列表
      userGroupList: [],
      //添加成员手机号
      addUser: "",
      //添加成员
      addUserShow: false,
      //权限列表
      jurisdictionList: [],
      //权限
      jurisdiction: "",
      //用户log
      userLogList: [],
      //查看的用户信息
      selectUserData: {},
      //管理员列表
      intendantList: [],

      //默认
      defaultGroup: {},


      //点击设置群组信息
      installGroupData: {},

      ScreenShow: false,

      //查询类型
      // selectType:1,
      type1: 1,
      type2: 0,
      //选中的功能
      handleSelectKey: null,
      //是否存在未读
      // notLook: null,

      //定时获取消息
      timeOut: null,
      //点击设置所拥有的的权限
      permissions: "",
      //手机号还是昵称
      folderSearchType: "1",
      //点击设置的团队类型
      teamSetGroupType: null,



      //权限设置显示
      permissionSettingShow: false,
      //权限列表
      authorityList: [],

      checkList: [],
      radio: false,
      radio2: false,



      //被设置权限的用户
      installUserId: "",
      newName: "",
    };
  },
  components: {
    ProjectCreate,
    Message
  },
  created() { },
  mounted() {
    document.title ="艺搭"

    this.headPortrait = localStorage.getItem("image");
    
    this.phone = localStorage.getItem("phone");
    this.userName = localStorage.getItem("userName");
    this.useMemory();

    //团队id
    let teamId = localStorage.getItem("teamId");
    //成员在该团队的等级

    let teamLevel = localStorage.getItem("teamLevel");

    if (teamId && teamLevel) {
      this.teamId = teamId;
      this.teamLevel = teamLevel;

      this.$store.commit("changeUserRank", teamLevel)

    }

    this.getUserGroupList();


    this.isNotLook();

    this.getUserSig()


    //点击空白隐藏
    window.addEventListener("click", e => {
      this.message = false;
      this.transitionTeam = false;
    });
  },
  destroyed() {
    window.addEventListener("click", e => { });
    clearInterval(this.timeOut);
  },
  watch: {},
  methods: {
    //改变全选与否
    changeType(item) {


      if (item.extendOfCompetence == true) {
        item.haveRight = item.jurisdictionList
      } else {
        item.haveRight = []

      }
    },



    //设置权限接口
    async addUserProjectAuthority(userId, groupId, authorityList) {
      let data = {
        userId: userId,
        groupId: groupId,
        authorityList: authorityList
      }
      const res = await this.$https.addUserProjectAuthority(data)

      if (res.success == true) {

        this.permissionSettingShow = false
      } else {
        this.$message(res.errMessage)
      }



    },
    //设置权限
    setPermission() {



      let authorityList = this.authorityList

      let setAuthorityList = []
      let setAuthorityList2 = []


      for (let index = 0; index < authorityList.length; index++) {

        for (let i = 0; i < authorityList[index].seedAuthority.length; i++) {


          if (authorityList[index].seedAuthority[i].extendOfCompetence == true) {


            setAuthorityList2.push(authorityList[index].seedAuthority[i].authority)
          }

          // setAuthorityList = setAuthorityList.concat(authorityList[index].seedAuthority[i].haveRight)

          var arr = authorityList[index].seedAuthority[i].haveRight
          setAuthorityList = [...setAuthorityList, ...arr]






        }

      }
      setAuthorityList = setAuthorityList.concat(setAuthorityList2)




      this.addUserProjectAuthority(this.installUserId, this.installGroupData.groupId, setAuthorityList)


    },


    //获取用户在团队中的权限
    async getUserProjectAuthority(userId, groupId) {
      let data = {
        userId: userId,
        groupId: groupId
      }
      const res = await this.$https.getUserProjectAuthority(data)

      if (res.success == true) {
        let UserProjectAuthority = res.data



        let authorityList = this.authorityList

        let index, i, j, authorityListLength, UserProjectAuthorityLength
        authorityListLength = authorityList.length
        UserProjectAuthorityLength = UserProjectAuthority.length

        for (index = 0; index < authorityListLength; index++) {


          for (i = 0; i < authorityList[index].seedAuthority.length; i++) {




            for (j = 0; j < UserProjectAuthorityLength; j++) {

              if (authorityList[index].seedAuthority[i].authority == UserProjectAuthority[j]) {
                authorityList[index].seedAuthority[i].extendOfCompetence = true
              }
              //切割
              let type = UserProjectAuthority[j].split("_")

              if (authorityList[index].seedAuthority[i].authority == type[0] + "_" + type[1]) {

                authorityList[index].seedAuthority[i].haveRight.push(UserProjectAuthority[j])
              }




            }


          }

        }




      } else {
        this.$message(res.errMessage)
      }


      // if(res.success==true){
      //   this.authorityList=res.data.authority
      // }


    },


    async getSeedAuthority() {
      const res = await this.$https.projectAuthority({})
      // if(res.success==true){
      //   this.authorityList=res.data.authority
      // }

      this.authorityList = res.authority

    },


    permissionSettingClick(id) {
      this.installUserId = id
      this.permissionSettingShow = !this.permissionSettingShow

      this.getSeedAuthority()

      this.getUserProjectAuthority(id, this.installGroupData.groupId)



    },
    //隐藏权限设置
    permissionSettingClose() {
      this.permissionSettingShow = !this.permissionSettingShow
    },


    //获取 userSing
    async getUserSig() {
      const res = await this.$https.getUserSig({

      })

      if (res.success == true) {

        localStorage.setItem('userSig', res.data)

        let userID = localStorage.getItem("phone")

        let test = localStorage.getItem("userSig")

        let _this = this
        // this.$tim.logout()
        let promise = this.$tim.logout();
        promise.then(function (imResponse) {
          // 登出成功
          _this.$tim.login({ userID: userID, userSig: res.data });

        }).catch(function (imError) {
          // console.warn('logout error:', imError);
        });


      }
    },


    setTeamSetVisible() {
      this.SearchPhone = "";
    },
    //是否存在未读
    async isNotLook() {
      let data = {};
      const res = await this.$https.isNotLook(data);

      if (res.success == true) {
        // this.notLook = res.data;
        this.$store.commit('changeUnread', res.data)

      } else {
        this.$message(res.errMessage);
      }
    },

    selectPhone() {
      this.getGroupUserList(this.page, this.pageSize);
    },

    confirmed(type) {

      this.ScreenShow = false;
      this.rank = type;
      this.getGroupUserList(this.page, this.pageSize);
    },

    sortord() {
      this.ScreenShow = !this.ScreenShow;
    },

    // 创建项目
    createProject(item) {
      this.$refs.ProjectCreate.createProject(item.groupId);
    },

    //默认项目的查看
    defaultView() {

      this.teamId = this.defaultGroup.groupId;
      this.teamLevel = this.defaultGroup.level;
      this.$store.commit("changeUserRank", this.defaultGroup.level)


      this.teamSetTeamName = this.defaultGroup.groupName;

      localStorage.setItem("teamId", this.teamId);
      localStorage.setItem("teamLevel", this.teamLevel);
      localStorage.setItem("groupName", this.teamSetTeamName);
      this.useMemory()

      this.$refs.ProjectCreate.getData(1, 15, this.teamId);
    },

    //点击查看项目
    viewProjec(item) {

      this.teamId = item.groupId;
      this.teamLevel = item.level;
      this.$store.commit("changeUserRank", item.level)

      this.teamSetTeamName = item.groupName;

      localStorage.setItem("teamId", this.teamId);
      localStorage.setItem("teamLevel", this.teamLevel);
      localStorage.setItem("groupName", this.teamSetTeamName);
      this.useMemory()

      this.$refs.ProjectCreate.getData(1, 15, this.teamId);
    },

    //获取列表
    async getIntendantList() {
      let data = {
        groupId: this.installGroupData.groupId
      };
      const res = await this.$https.adminList(data);
      if (res.success == true) {
        this.intendantList = res.data;
      } else {
        this.$message(res.errMessage);
      }
    },

    //点几删除用户
    innerDelete() {
      this.deleteUser();
      this.innerVisible = false;
    },
    //删除部门内用户
    async deleteUser() {
      let data = {
        groupId: this.installGroupData.groupId,
        target: this.selectUserData.id
      };

      const res = await this.$https.deleteUser(data);

      if (res.success == true) {
        this.$message("恭喜您删除成功");

        this.getGroupUserList(this.page, this.pageSize);

        // this.userInformation()

        // this.teamSetVisible = false;
      } else {
        this.$message(res.errMessage);
      }
    },

    //获取权限列表
    async getLevelList(groupId) {
      let data = {
        groupId: groupId
      }
      const res = await this.$https.levelList(data);

      if (res.success == true) {
        this.jurisdictionList = res.data;
      } else {
        this.$message(res.errMessage);
      }
    },
    //更改用户权限
    innerOk() {
      if (this.selectUserData.level != 3) {
        this.updateUserLevel();

        // this.teamSetVisible=false
      }
      this.innerVisible = false;
      this.getGroupUserList(this.page, this.pageSize);
      this.getUserGroupList();
    },

    //更改用户权限
    async updateUserLevel() {
      let data = {
        id: this.selectUserData.id,
        groupId: this.installGroupData.groupId,
        level: this.jurisdiction
      };

      const res = await this.$https.updateUserLevel(data);

      if (res.success == true) {
        this.$message("恭喜您操作成功");
      } else {
        this.$message(res.errMessage);

      }
    },

    //用户信息日志回显
    async userInformation(item) {
      let data = {
        id: item.userId,
        groupId: this.installGroupData.groupId
      };

      const res = await this.$https.userInformation(data);
      if (res.success == true) {
        this.userLogList = res.data.log;
        this.selectUserData = res.data;
        this.jurisdiction = res.data.level;
      } else {
        this.$message(res.errMessage);
      }
    },
    //点击删除团队
    myDeleteTeam() {
      this.$confirm("此操作将删除团队, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteTeam();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //调用接口 删除团队
    async deleteTeam() {
      let data = {
        groupId: this.installGroupData.groupId
      };

      const res = await this.$https.deleteGroup(data);

      if (res.errCode == null) {
        this.$message("操作成功");
        this.getUserGroupList();
        this.teamSetVisible = false;
        this.SearchPhone = "";
        this.defaultView()
        this.handleSelectKey = "1-2"

      } else {
        this.$message(res.errMessage);
      }
    },
    myQuitTeam() {

      if (this.installGroupData.level == 3) {
        this.remind = true;
      } else {
        this.$confirm("此操作将退出团队, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.quitTeam();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      }



    },
    //退出团队
    async quitTeam() {
      let data = {
        groupId: this.installGroupData.groupId
      };

      const res = await this.$https.exitGroup(data);

      if (res.errCode == null) {
        this.$message("操作成功");
        this.teamSetVisible = false;
        this.SearchPhone = "";

        this.defaultView()

        this.getUserGroupList();
      } else if (res.errCode == 412) {
        this.remind = true;
      } else {
        this.$message(res.errMessage);
      }
    },

    //移交操作
    async turnOver(id) {
      let data = {
        groupId: this.installGroupData.groupId,
        target: id
      };
      const res = await this.$https.transferGroup(data);
      if (res.success == true) {
        this.teamSetVisible = false;
        this.SearchPhone = "";

        this.transitionTeam = false;

        this.$message("操作成功");
      } else {
        this.$message(res.errMessage);
      }
      this.getUserGroupList();
    },

    //显示移交团队操作
    HandeTeamShow() {
      this.transitionTeam = !this.transitionTeam;
      this.getIntendantList();
    },

    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.listLoading = true;
      this.getGroupUserList(val, this.pageSize);
    },
    //获取团队成员
    async getGroupUserList(page, count) {
      let data = {
        page: page,
        count: count,
        groupId: this.installGroupData.groupId,
        rank: this.rank,
        phone: this.SearchPhone,
        search: this.folderSearchType
      };

      const res = await this.$https.groupUserList(data);
      if (res.success == true) {
        this.gridData = res.data.userListCOS

        this.totals = res.data.total;

        this.permissions = res.data.level

      } else {
        this.$message(res.errMessage);
      }
    },

    //添加成员
    setAddUser() {
      this.addUserShow = true;
    },

    //添加用户隐藏
    addUserClose() {
      this.addUserShow = false;
      this.addUser = "";
    },
    //确认添加用户
    addUserOK() {
      this.sqlAddUser();
    },

    async sqlAddUser() {
      let data = {
        groupId: this.installGroupData.groupId,
        phone: this.addUser
      };
      const res = await this.$https.addUser(data);

      if (res.success == true) {
        this.$message("添加成功");
        this.addUser = "";
        this.addUserShow = false;
        this.getGroupUserList(this.page, this.pageSize);

        // this.userInformation()
        // this.teamSetVisible = false;
      } else {
        this.$message(res.errMessage);
      }
    },

    //在团队中提示
    remindClose() {
      this.remind = false;
    },
    //点击修改团队名称
    changeTeamName() {
      this.updataName = true;

    },
    //修改团队名称取消
    updataNameClose() {
      this.updataName = false;
      this.newName = ""
    },
    //  确认修改团队名称
    updataNameOk() {
      this.sqlUpdateGroupName();
      this.getUserGroupList();
    },
    // 修改团队名称接口
    async sqlUpdateGroupName() {
      let data = {
        groupId: this.installGroupData.groupId,
        newName: this.newName
        //  this.installGroupData.groupName
      };

      const res = await this.$https.updateGroupName(data);

      if (res.success == true) {
        this.$message("修改成功");
        this.updataName = false;
        this.installGroupData.groupName = this.newName
        this.getUserGroupList();
      } else {
        this.$message(res.errMessage);
      }
    },

    // 显示历史消息

    messageHistoryShow() {
      this.messageHistory = true;
    },
    //关闭历史消息
    messageHistoryClose() {
      this.messageHistory = false;
    },

    //显示权限说明
    specificationShow() {
      this.permissionStatement = true;
    },

    //关闭权限说明
    permissionClose() {
      this.permissionStatement = false;
    },

    //关闭成员设置
    handleClose() {
      this.innerVisible = false;
    },

    //点击成员设置
    membersSet(item) {
      this.innerVisible = true;

      this.userInformation(item);
    },

    //设置表头的颜色
    rowClass({ row, rowIndex }) {

      return "background:#fff; border-bottom: 1px solid rgba(33, 35, 49, 0.1);";
    },
    //设置表格
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "background:#EFF3F4";
    },

    //菜单选择
    handleSelect(key) {
      // let operation = key.slice(-2)
      // let id= key.slice(0,key.length-2)

      // //团队设置
      // if (operation == "-1") {
      //   this.teamSet(id);
      // }
      this.handleSelectKey = key;
    },

    //团队设置
    teamSet(item) {
      // let groupId = item.groupId;

      // this.teamId = groupId;
      // this.teamLevel = item.level;

      // this.teamSetTeamName = item.groupName;

      this.teamSetGroupType = item.type

      this.installGroupData = item;

      this.getLevelList(item.groupId);



      // this.permissions = item.level;

      this.teamSetVisible = true;

      // localStorage.setItem("teamId", groupId);
      // localStorage.setItem("teamLevel", item.level);
      this.getGroupUserList(this.page, this.pageSize);
    },

    //获取用户创建团队列表
    async getUserGroupList() {
      const res = await this.$https.userGroupList({});
      if (res.success == true) {

        this.defaultGroup = res.data.default;
        this.defaultGroup.type = "defaultGroup"

        // this.userGroupList = res.data.joinOrCreate;

        let userGroupList = res.data.joinOrCreate.map((ele) => {

          let number1 = Math.floor(Math.random() * 100 % 5)

          ele.imgName = 'TeamIcon' + number1 + '.png'
          ele.type = "joinOrCreate"
          return ele

        })
        this.userGroupList = userGroupList


      } else {
        this.$message(res.errMessage);
      }
    },

    async logoutArgument() {
      let phone = localStorage.getItem("phone");

      const res = await this.$https.logout({
        phone: phone
      });

      if (res.success == true) {
        // this.$message({
        //   showClose: true,
        //   message: res.errMessage,
        //   type: "success"
        // });

        let promise = this.$tim.logout();
        promise.then(function (imResponse) {
          // 登出成功

        }).catch(function (imError) {
          // console.warn('logout error:', imError);
        });
        this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
        localStorage.clear(); //清楚缓存addMemberPage
      } else if (res.errCode == "411") {
        this.$message({
          showClose: true,
          message: res.errMessage
          // type: "warning"
        });
        this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
      } else if (res.errCode == "412") {
        this.$message({
          message: res.errMessage
          // type: "warning"
        });
        this.$router.push({ path: "/OfficialWebsite/HomePage/HomePageContent" });
      } else if (res.errCode == "500") {
        this.$message({
          showClose: true,
          message: res.errMessage
          // type: "warning"
        });
      }
    },
    pmpChange() {
      this.pmpState = true;
    },
    exitChange() {
      (this.pmpState = !this.pmpState),
        // //退出登录
        // this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning"
        // })
        //   .then(() => {
        this.logoutArgument();
      this.$message({
        // type: "info",
        message: "已退出"
      });
      // })
      // .catch(() => {
      //   this.$message({
      //     type: "info",
      //     message: "已取消退出"
      //   });
      // });
    },
    menuChange() { },
    //获取使用空间
    async useMemory(_type) {

      let teamId = localStorage.getItem("teamId")
      const res = await this.$https.useMemory({
        groupId: teamId
      });
      // loading.close();

      if (res.errCode == null) {
        // 空间大小
        this.storage = res.data.totalMemory;
        this.usedspace = res.data.memory;
        this.percentage = Math.ceil(res.data.memory / res.data.totalMemory);

      } else {
        this.$message({
          message: res.errMessage
          // type: "warning"
        });
      }
    },
    messageChange() {
      this.message = !this.message;
    },
    userChange() {
      this.$router.push({ path: "/PersonalData" });
    }
  },
  filters: {
    formatbytes: function (bytes) {
      if (bytes === 0) return "0 B";
      var k = 1024, // or 1024
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.round(Math.log(bytes) / Math.log(k));

      return (bytes / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    },


    calculatePercentage: function (data) {
      let data1 = (data * 100).toFixed(4) + "%";
      return data1;
    }
  }
};
</script>
<style scoped>
.fl {
  float: left;
}

.fr {
  float: right;
}

.manage {
  width: 400px;
  height: calc(100% - 140px);
  background: #fff;
  border-left: none;
  position: fixed;
  right: 0;
  top: 50px;
  overflow-y: auto;
}

.manage::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* 滚动条的滑块 */
.manage::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
  border-radius: 3px;
}

/* .el-menu--inline .is-active {
  border: 2px solid rgba(5, 96, 253, 0.7);
  border-radius: 10px;
  background: none;
} */

.version {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #fff;
  /* text-align: right; */
  position: fixed;
  top: 0;
  left: 0;
  font-size: 12px;
}

.version .header_logo {
  width: 60px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
}

.version .header_logo img {
  width: 60px;
  height: 30px;
}

.version .message {
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 80px;
}

.topFlag {
  position: relative;
  top: -6px;
  right: 8px;
  width: 6px;
  height: 6px;
  background: #ff7502;
  border-radius: 50%;
}

.version .message img {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.userPosition {
  position: fixed;
  top: 40px;
  right: 0px;
  z-index: 999;
  width: 400px;
  height: 100px;
  background: #fff;
}
.listContent .bottom{
    margin-top:50px;
}

.user {
  display: flex;
  height: 100px;
  border-bottom: 1px solid #f7f7f7;
  align-items: center;
  cursor: pointer;
}

.user .logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* border: 1px solid #1673ff; */
  margin-left: 20px;
  cursor: pointer;
}

.user .number {
  font-weight: bold;
  margin-left: 40px;
}

.pmp {
  height: calc(100% - 200px);
  margin-top: 90px;
}

.project {
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  /* margin-top: 10px; */
}

.el-menu-item {
  width: 350px !important;
  height: 50px;
  display: flex;
  /* align-items: center; */
  margin-left: 25px;
}

.project span {
  margin-left: 15px;
  font-weight: 400;
}

.project img {
  width: 16px;
  height: 16px;
}

.content {
  width: calc(100% - 400px);
  height: calc(100% - 42px);
  position: fixed;
  left: 0;
  top: 42px;
}

.header {
  width: 95%;
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header p {
  font-weight: bold;
}

.cloudPosition {
  width: 410px;
  position: fixed;
  bottom: 0px;
  background: #fff;
}

.cloud {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-progress {
  width: 220px;
  height: 28px;
  margin-left: 10px;
}

.progress_box {
  display: flex;
}

.progress_box {
  width: 220px;
  height: 28px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  /* border: 2px solid #1673ff; */
  border-radius: 15px;
  margin-left: 10px;
}

.el-progress {
  width: 215px;
  /* height: 20px; */
  margin-left: 2px;
  /* margin-bottom: 2px; */
}

.progress_box>>>.el-progress-bar__inner {
  background: #1673ff;
}

.progress_box>>>.el-progress-bar__innerText {
  color: #333;
}

.progress_box>>>.el-progress-bar {
  /* margin-top:-2px ; */
}

.progress_box>>>.el-progress-bar__outer {
  border: 2px solid #1673ff;
}

.memory {
  height: 80%;
  margin-left: 20px;
  font-size: 14px;
  color: rgba(153, 158, 161, 1);
  display: flex;
  align-items: center;
}

.teamSetTop {
  width: 100%;
  height: 50px;
  /* background: red; */
  /* margin-top: -20px; */
  border-bottom: 1px solid #eff3f4;
}

.teamSetTeamName {
  width: 180px;
  float: left;
  margin-right: 10px;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 4px;
  height: 35px;
  line-height: 20px;
}

.teamSetTeamName .teamName {
  float: left;

  width: 150px;
  margin-left: 10px;
  /* height: 42px; */
  line-height: 35px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  /* line-height: 30px; */
}

.teamSetTop .icon {
  line-height: 38px;
}

.teamOperation {
  /* width: 22px; */
  height: 34px;
  /* background-color: #212134; */
  float: left;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 16, 0.1);
  border-radius: 6px;
}

.teamOperation .teamMembers {
  width: 130px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0560fd;
  line-height: 34px;
  margin-left: 10px;
}

.teamOperation .operation {
  width: 90px;
  text-align: center;
  /* height: 14px; */
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 34px;
}

.teamSetTop .teamSetTopRight {
  /* width: 400px; */
  height: 42px;
  /* background: #212134; */
}

.teamSetTop .sort {
  width: 30px;
  height: 34px;
  margin: 0 10px;
  position: relative;
  text-align: center;
  line-height: 34px;
  font-size: 12px;
}

.teamSetTop .sortShow {
  width: 30px;
  height: 40px;
  margin: 0 10px;
  position: relative;
  text-align: center;
  line-height: 34px;

  box-shadow: 0px 0px 4px 0px rgba(5, 96, 253, 0.2);
  color: rgba(5, 96, 253, 1);
}

.teamSetTop .sort .filtrateContainer {
  position: absolute;
  top: 40px;
  left: -90px;
  z-index: 99999;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(5, 96, 253, 0.2);
  border-radius: 8px;
  border-top-right-radius: 0px;
}

.teamSetTop .sort .filtrateItem {
  width: 120px;
  /* height: 14px; */
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.filtrate {
  /* line-height: 40px; */
  font-size: 14px;
  line-height: 38px;
}

.teamSetTop .teamSetTopRight .search {
  width: 207px;
}

.teamSetTop .teamSetTopRight .search /deep/ .el-input__inner {
  height: 34px;
  line-height: 34px;
}

.teamSetTop .teamSetTopRight .addTeam {
  line-height: 42px;
  height: 34px;
  /* width: 57px; */
  /* height: 14px; */
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 34px;
}

.dataTable {
  margin-top: 20px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  border-radius: 10px;
  margin-bottom: 30px;
}

.dataTable /deep/ .el-table__row>td {
  border: none;
}

.dataTable /deep/ .el-table::before {
  height: 0px;
}

.dataTable .settingUp {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0560FD;
  line-height: 30px;
}

.dataTable .partingLine {
  margin: 0 5px;
  color: #0560FD;

}

.dataTable .authority {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0560FD;
  line-height: 30px;
}


.paging {
  /* width: 30%; */
  /* margin: 0 auto; */
  height: 30px;
  float: right;
  margin: -12px 0;
}

.transitionTeam {
  position: absolute;
  top: 100px;
  left: 110px;
  width: 600px;
  height: 300px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
  border-radius: 8px;
  z-index: 99;
}

.transitionTeam .transitionTeamList {
  margin: 30px auto;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  border-radius: 12px;
  width: 550px;
}

/* 成员设置 */

.membersSetTitle {
  width: 90%;
  /* height: 60px; */
  position: absolute;
  top: 20px;
  /* background: red; */
}

.membersSetTitle .titleText {
  width: 70px;
  margin: 0 auto;
  /* background: #1673ff; */
  margin: 0 auto;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 700;
  color: #333;
  line-height: 30px;
}

.membersSetTop {
  width: 100%;
  height: 100px;
  /* background: #1673ff; */
}

.membersSetTop .userData {
  width: 45%;
  height: 100px;
  /* background: red; */
  float: left;
}

.membersSetTop .userData .userImg {
  width: 24%;
  height: 100px;
  float: left;
  /* background: #000000; */
  text-align: center;
  line-height: 140px;
}

.membersSetTop .userData .userImg img {
  display: inline-block;
  width: 60px;
  /* height: 0px; */
  /* margin: 0 auto; */
  /* margin-left: 50px; */

  border-radius: 50%;
}

.membersSetTop .userPhone {
  width: 76%;
  height: 100px;
  float: right;
  /* background-color: red */
}

.membersSetTop .userPhone .phone {
  width: 104px;
  height: 12px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  margin-top: 15px;
}

.membersSetTop .userPhone .time {
  margin-top: 20px;
  width: 200px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}

.membersSetTop .setStatus {
  float: right;
  width: 50%;
  height: 100px;
  /* background:saddlebrown; */
}

.selectBox {
  display: flex;
  width: 90%;
  height: 25px;
  margin: 8px auto;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 12px;
  margin-top: 40px;
}

.selectBox .ipt_box {
  width: 260px;
  height: 30px;
  box-shadow: 0px 0px 1px 0px rgba(33, 35, 49, 0.1);

  border-radius: 6px;
  display: flex;
  align-items: center;
}

.selectBox .ipt_box .el-select-dropdown {
  box-shadow: 0px 0px 1px 0px rgba(33, 35, 49, 0.1);
}

.selectBox .select {
  width: 260px;
  height: 28px;
  outline: none;
  color: #808080;
  border: none;
  border-radius: 4px;
  /* margin-left: 10px; */
}

.selectBox .select /deep/ .el-input {
  width: 260px !important;
  height: 35px !important;
  outline: none !important;
}

.selectBox .select /deep/ .el-input__inner {
  width: 260px !important;
  height: 28px !important;
  outline: none !important;

}

.search /deep/ .el-input__inner {
  padding-left: 50px;
}

.selectBox .select /deep/ .el-select__caret {
  height: 28px !important;
  line-height: 28px !important;
}

.selectBox p {
  width: 80px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
}

.indistinctOrExact {
  width: 20px;
}

.search .select /deep/ .el-input {
  width: 75px !important;
  /* height: 35px !important; */
  outline: none !important;
  line-height: 35px !important;
  text-align: center;
  border: none;
  margin-left: -2px;
}

.search .select /deep/ .el-input__inner {
  border-radius: 7px;
  border: none;
  background-color: #f7f7f7;
  /* height: 25px; */
  /* padding: 5px; */
  height: 28px !important;
  margin-top: -10px;

}

.search .select /deep/ .el-input--suffix .el-input__inner {
  padding: 0;
  padding-left: 5px;
  /* text-align:center; */
}

.search .select /deep/.el-input__inner {
  /* height: 24px; */
  margin-top: 0px;

}

.search .select /deep/.el-input__icon {
  line-height: 35px;

}

.search .select /deep/.el-select .el-input .el-select__caret {
  font-size: 10px;
}

.search .select /deep/.el-input__suffix {
  right: 2px;
}


.search /deep/.el-input__inner .handle_conter {
  height: 20px;
  float: left;
  width: 30px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}


.search /deep/ .el-input--suffix .el-input__inner {
  padding-right: 25px;
}

.search /deep/ .el-input--prefix .el-input__inner {
  padding-left: 80px;
}

.search /deep/.el-input__suffix .el-input__suffix-inner .el-icon-circle-close:before {
  content: "\e79d" !important;
  font-size: 18px;
}



.userLog {
  /* padding: 10px; */
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 300px;
  /* background: red; */
  overflow-y: auto;
  padding: 20px;
  padding-top: 40px;

  /* height: 280px; */
  background: #ffffff;
  border: 1px solid #eff3f4;
  border-radius: 8px;
}

.userLog::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.userLog::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
}

.userLog::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #e9f1fe;
}

.userLog p {
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}

.title {
  position: relative;
  width: 80%;
  height: 45px;
  left: 25px;
  top: 46px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 34px;
  z-index: 9999;
  background-color: #fff;
}

.userBtn {
  width: 100%;
  /* height: 100px; */
  /* margin-top: 30px */
}

.userBtn .btn {
  width: 400px;
  height: 50px;
  margin: 0 auto;
  margin-top: 50px;
}

.btn .ackButton {
  float: left;
  width: 180px;
  height: 40px;
  background: #0560fd;
  border-radius: 8px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
}

.btn .deleteButton {
  float: right;
  width: 180px;
  height: 40px;
  background: #ff7502;
  border-radius: 8px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
}

.permissionType {
  width: 100%;
  height: 100px;
  /* background: red; */
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 8px;
  margin-bottom: 15px;
}

.permissionType .permissionTypeLeft {
  float: left;
  width: 150px;
  height: 100px;
  /* background-color: #1673ff; */
  margin-left: 10px;
}

.permissionTypeLeft .userIcon {
  width: 130px;
  height: 70px;
  /* background-color: #000; */
  margin: 0 auto;

  border-right: 1px solid #edf1f2;

  text-align: center;
  margin-top: 20px;
}

.permissionTypeLeft .userIcon img {
  width: 42px;
  height: 42px;
}

.permissionTypeLeft .userIcon .membership {
  width: 80px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  /* color: #0560FD; */
  line-height: 30px;
  margin: 0 auto;
}

.permissionTypeRight {
  float: right;
  width: 630px;
  height: 70px;
  /* background-color:#FF7502; */
  color: #333333;
  line-height: 30px;
  margin-top: 20px;
  padding-left: 20px;
}

.permissionTypeRight .permissionName {
  float: left;
  width: 130px;

  height: 30px;
  /* background: #808080; */
  /* margin: 2px; */
  text-align: center;
  /* margin-right: 50px; */

  border-radius: 6px;
  margin: 0 10px;
  margin-bottom: 10px;

}

.permissionTypeRight .superAdmin {
  background: rgba(2, 1, 251, 0.1);

}

.permissionTypeRight .admin {
  background: rgba(255, 117, 2, 0.1);

}

.permissionTypeRight .redactor {
  background: rgba(47, 204, 136, 0.1);
}


.permissionTypeRight .separate {
  margin: 0 17px;
}

.allMessages {
  /* height: 450px; */
  /* width: 90%; */
  margin: 0 auto;
  margin-top: -20px;
}

.name {
  /* width: 98%; */
  width: 420px;
  height: 40px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  padding-right: 25px;
}

.name p {
  width: 160px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 12px;
  font-weight: bolder;
}

.ipt_box {
  width: 360px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ipt_box .input {
  width: 250px;
  height: 35px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0);
  /* padding-left: 15px; */
}

.enter {
  width: 100%;
  height: 50px;
  /* position: fixed; */
  /* bottom: 0; */
  font-size: 12px;
  display: flex;
  margin-top: 30px;
}

.enter .set {
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.verify {
  background-color: #0560fd;
  color: #fff;
  /* margin-left: 20px; */
  /* margin-top: 15px; */
  /* margin-left: 15px; */
  margin-right: 10px;
}

.cancel {
  color: #999;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  /* margin-top: 15px; */
  /* margin-left: 15px; */
  margin-left: 10px;
}

/deep/ .el-dialog {
  border-radius: 8px;
}

.remind {
  width: 100%;
  height: 90px;
}

.remind .remindIcon {
  float: left;
  width: 100px;
  height: 90px;
  line-height: 140px;
  text-align: center;
}

.remind .remindIcon img {
  width: 45px;
  height: 45px;
}

.remind .remindText {
  float: right;
  width: 327px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  margin-top: 20px;
}

.turn {
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0560fd;
  /* line-height: 56px; */
}

.titleIcon {
  /* background: red; */
  /* line-height: 40px; */
  text-align: center;
  margin-right: 20px;
  /* line-height: 20px; */
}

.titleIcon>img {
  width: 15px;
  /* height: 19px; */
  margin-top: -2px;
}

.lineBetween {
  width: 80%;
  height: 1px;
  margin: 0 auto;
  /* box-shadow: 0px 0px 4px 0px rgba(33,35,49,0.6); */
  border-bottom: RGBA(238, 237, 242, 1) 1px solid;
  margin-top: 5px;
  margin-bottom: 5px;
}

.topMessage {
  box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.2);
  position: absolute;
  right: 40px;
  top: 40px;
}

/deep/.el-submenu__title {
  padding: 0 !important;
  padding-left: 5px !important;
  margin: 0 20px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .is-active .el-submenu__title {
  border-left: 5px solid #0560FD;
  background-color: RGBA(232, 239, 255, 1);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* /deep/ .is-active {
  border-left: 5px solid #0560FD;
  background-color: RGBA(232, 239, 255, 1);
} */

/* 个人项目 */
.personage {
  width: 100%;
  height: 80px;
  line-height: 80px;
  padding: 0 10px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
}

/* 权限设置 */
.contentInterval {
  width: 96%;
}

.accessContent {
  width: 103%;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  /* background: red; */
}

.accessContent::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;

}

.accessContent::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #69A0FE;
  background: #69A0FE;
}

.accessContent::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  background: #E9F1FE;

}



.accessContent .accessContent_item {

  /* background-color: #0560FD; */

}

.accessContent .accessContent_item .accessContent_item_left {
  /* background-color: #808080; */

  background: rgb(239, 243, 244);
  width: 100%;
  /* float: left; */
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  margin-bottom: 20px;
}

/* .accessContent .accessContent_item .accessContent_item_left_title{
  margin-left: 10px;
} */

.accessContent .accessContent_item .accessContent_item_right {
  /* background-color: #ff7502; */
  width: 90%;
  float: right;
  margin-top: 50px;
}

.accessContent .accessContent_item .accessContent_item_right .accessContent_right_item {
  /* background-color: #333; */
}

.accessContent .accessContent_item .accessContent_item_right .accessContent_right_item .accessContent_type {
  float: left;
  width: 40%;
  /* background-color: chartreuse; */
}

.accessContent .accessContent_item .accessContent_item_right .accessContent_right_item .accessContent_childRights {
  width: 60%;
  /* background-color: white; */
  float: left;
}



.accessContent .accessContent_item .accessContent_item_right .accessContent_right_item .accessContent_childRights_title {
  /* background: red; */
}

.accessContent .accessContent_item .accessContent_item_right .accessContent_right_item .accessContent_childRights_content {
  width: 100%;
}


.accessContent .accessContent_item .accessContent_item_right .accessContent_right_item .SelectPermissions {
  width: 45%;
  float: right;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}


.accessContent .accessContent_item .crossLine {
  width: 90%;
  height: 50px;
  /* border-bottom: #69A0FE solid 2px; */
  /* background: #000; */
  margin: 10px auto;
  clear: both;
}

.listContent .itemList {
  border-bottom: 1px solid #EFF3F4;
  padding-bottom: 20px;
 margin-bottom: 10px;
}

.listContent .itemTop {
  height: 100px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

/* .listContent .itemTop .imgBox{
  width: 50px;
  margin: 0 auto;
  margin-bottom: 10px;
} */
.listContent .itemTop .itemTopImg {
  width: 50px;
  margin-bottom: 5px;

}

.listContent .itemTop .left {
  width: 80px;
  height: 90px;
  /* background-color: red; */

}

.listContent .itemTop .right {
  width: 80px;
  height: 90px;
  /* background-color: blue; */

}


.listContent .itemList .listItem {
  width: 100%;
  height: 80px;
  background: RGBA(242, 247, 255, 1);

  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;

  justify-content: space-between;
  line-height: 80px;

}

.listContent .itemList .listItem .listItemIcon {
  line-height:105px;
  margin-left: 20px;
  margin-right: 20px;
}


.listContent .itemList .listItem .listItemImg {
  width: 40px;
  height: 40px;


}

.listContent .itemList .listItem .listItemText {
  color: #000000;
  width: 200px;
  /* background-color: #69a0fe; */
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;

}

.listContent .itemList .listItem .icon {
  width: 30px;
  line-height: 85px;

}

.listContent .bottom {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 70px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;

  cursor: pointer;
}
</style> 

 
